export default class extends Controller {
  static targets = ['content', 'form']

  connect() {
    console.log(this)
  }

  save() {
    this.formTarget.requestSubmit()
  }
}