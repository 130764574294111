import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['root', 'child', 'record']

  connect() {
    if (this.hasRootTarget) {
      let rootID = parseInt(this.rootTarget.selectedOptions[0].value, 10)

      if (rootID > 0) { this.getChildren(rootID) }
    }
  }

  chooseRoot(e) {
    e.preventDefault()

    let target = e.target
    let rootID = target.selectedOptions[0].value

    if (!rootID) return

    this.getChildren(rootID)
  }

  removeOtherOptions() {
    let targetOptions = Array.from(this.childTarget.children).filter(option => option.value.length > 0)
    targetOptions.map(option => option.remove())
  }

  getChildren(rootID) {
    // TODO: Select first
    Rails.ajax({
      type: 'get',
      url: `/categories/${rootID}/children`,
      success: (rep, status, xhr) => {
        this.removeOtherOptions()
        Array.from(rep.body.children).forEach(option => {
          this.childTarget.appendChild(option)
          if (option.value == this.childTarget.dataset.recordid) {
            option.selected = true
          }
        })
      }
    })
  }

  toggleDetail(e) {
    let id = e.target.parentElement.parentElement.parentElement.id
    let target = $(`#${id}-detail`)
    let caret = $(e.target)

    target.toggleClass('d-none')

    caret.addClass('fa-caret-down').removeClass('fa-caret-right')
    if (target.hasClass('d-none')) {
      caret.addClass('fa-caret-right').removeClass('fa-caret-down')
    }
  }
}
