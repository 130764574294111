export default class extends Controller {
  static targets = ['category']

  static values = {
    hasUploaded: Boolean
  }

  connect() { this.oldCategoryId = this.categoryTarget.dataset['recordid'] }

  submit(e) {
    if (this.hasUploadedValue) {
      e.preventDefault()

      if (this.categoryTarget.value != this.oldCategoryId) {
        Swal.fire({
          icon: 'question',
          title: '確定要更改嗎？',
          text: '若更改送審類別，則已上傳之檔案會全數刪除，確定要更改嗎？',
          showCancelButton: true,
          customClass: { confirmButton: 'btn btn-sm btn-primary', cancelButton: 'btn btn-sm btn-secondary' },
          confirmButtonText: '確定更改',
          cancelButtonText: '取消變動',
        }).then(result => {
          if (result.isConfirmed) {
            Rails.ajax({
              type: 'POST',
              url: this.element.action,
              data: new FormData(this.element)
            })
          } else {
            $(this.element).find('[type="submit"]').attr('disabled', false)
          }
        })
      } else {
        Rails.ajax({
          type: 'POST',
          url: this.element.action,
          data: new FormData(this.element)
        })
      }
    }
  }
}
