export default class extends Controller {
  static values = {
    persisted: Boolean
  }

  saved(e) {
    if (this.persistedValue) {
      this.updated(e)
    } else {
      this.created(e)
    }
  }

  created(e) {
    Swal.fire({
      position: 'top-right',
      icon: 'success',
      title: '已成功新增',
      customClass: { confirmButton: 'btn btn-sm btn-primary', cancelButton: 'btn btn-sm btn-secondary' },
      showConfirmButton: false,
      timer: 3000
    }).then(() => window.location.reload())
  }

  updated(e) {
    Swal.fire({
      position: 'top-right',
      icon: 'success',
      title: '已成功更新',
      showConfirmButton: false,
      customClass: { confirmButton: 'btn btn-sm btn-primary', cancelButton: 'btn btn-sm btn-secondary' },
      timer: 3000
    }).then(() => window.location.reload())
  }
}

