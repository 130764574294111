export default class extends Controller {
  static targets = ['new']
  static values = { newUrl: String, id: Number }

  connect() {
    this.aside = $('#dynamic-aside-wrapper').get(0)
    this.rightAside = null
    this.initializeAsideForm()
    this.initializeDropzone()
  }

  initializeDropzone() {
    Dropzone.autoDiscover = false

    if (document.querySelector('.dropzoneArea')) {
      let method = 'post'

      this.dz = new Dropzone('.dropzoneArea', {
        addRemoveLinks: true,
        url: this.element.action,
        paramName: 'company[documents]',
        autoProcessQueue: false,
      })

      this.dz.on('sending', function (file, xhr, formData) {
        xhr.setRequestHeader('accept', 'application/javascript')
        let form = this.element

        ;[...(new FormData(form)).entries()].forEach(pair => {
          formData.append(pair[0], pair[1])
        })
      }.bind(this)).on('success', function (file, rep) {
        let script = document.createElement('script')
        script.innerHTML = rep
        document.body.appendChild(script)
      })
    }

  }

  uploadDocument(e) {
    e.preventDefault()
    e.stopPropagation()

    if (this.dz.getQueuedFiles().length > 0) {
      this.dz.processQueue()
    } else {
      Rails.ajax({
        type: 'POST',
        url: this.element.action,
        data: new FormData(this.element)
      })
    }
  }

  showForm(e) {
    e.preventDefault()

    $.get(this.newUrlValue, function (rep) {
      $('#dynamic-aside-container').html(rep)

      if (!this.rightAside.isShown()) { this.rightAside.show() }
    }.bind(this))
  }

  newProject(e) {
    let [ rep, message, xhr ] = e.detail
    let formPage = rep.children[0].querySelector('body').innerHTML

    $('#dynamic-aside-container').html(formPage)

    if (!this.rightAside.isShown()) { this.rightAside.show() }
  }

  newVoices(e) {
    let [ rep, message, xhr ] = e.detail
    let formPage = rep.children[0].querySelector('body').innerHTML

    $('#dynamic-aside-container').html(formPage)

    if (!this.rightAside.isShown()) { this.rightAside.show() }
  }

  newDocuments(e) {
    let [ rep, message, xhr ] = e.detail
    let formPage = rep.children[0].querySelector('body').innerHTML

    $('#dynamic-aside-container').html(formPage)

    if (!this.rightAside.isShown()) { this.rightAside.show() }
  }

  initializeAsideForm() {
    this.rightAside = new KTOffcanvas('dynamic-aside', {
      baseClass: 'aside',
      overlay: true,
      closeBy: 'close-btn',
      toggleBy: ['new-company-btn']
    })

    KTUtil.scrollInit(this.aside, {
      disableForMobile: true,
      resetHeightOnDestroy: true,
      handleWindowResize: true,
      height: function () {
        let height = parseInt(KTUtil.getViewPort().height, 10)
        height = height - (parseInt(KTUtil.css(this.aside , 'marginBottom')) + parseInt(KTUtil.css(this.aside, 'marginTop')));

        return height
      }.bind(this)
    })
  }

  toggleDetail(e) {
    let id = e.target.parentElement.parentElement.parentElement.id
    let target = $(`#${id}-detail`)
    let caret = $(e.target)

    target.toggleClass('d-none')

    caret.addClass('fa-caret-down').removeClass('fa-caret-right')
    if (target.hasClass('d-none')) {
      caret.addClass('fa-caret-right').removeClass('fa-caret-down')
    }
  }

  removeVoice(e) {
    console.log(e)
  }
}
