import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['new']
  static values = { newUrl: String, id: Number }

  connect() {
    this.aside = $('#dynamic-aside-wrapper').get(0)
    this.rightAside = null
    this.initializeAsideForm()

    // $(document).on('click', '.news-items-controller [data-swal-confirm]', this.destroyConfirm.bind(this))
  }

  showForm(e) {
    e.preventDefault()

    let link = e.srcElement

    $.get(link.href, function (rep) {
      $('#dynamic-aside-container').html(rep)

      if (!this.rightAside.isShown()) { this.rightAside.show() }
    }.bind(this))
  }

  initializeAsideForm() {
    this.rightAside = new KTOffcanvas('dynamic-aside', {
      baseClass: 'aside',
      overlay: true,
      closeBy: 'close-btn',
      toggleBy: ['new-company-btn']
    })

    KTUtil.scrollInit(this.aside, {
      disableForMobile: true,
      resetHeightOnDestroy: true,
      handleWindowResize: true,
      height: function () {
        let height = parseInt(KTUtil.getViewPort().height, 10)
        height = height - (parseInt(KTUtil.css(this.aside , 'marginBottom')) + parseInt(KTUtil.css(this.aside, 'marginTop')));

        return height
      }.bind(this)
    })
  }

  toggleFinish(e) {
    e.preventDefault()

    let target = e.target

    let link = target.dataset.url

    Rails.ajax({ url: link, type: 'patch' })
  }

  destroy(e) {
    e.preventDefault()

    let target = e.path.filter(target => (target.tagName + '') == 'A')[0]
    let link = target.href

    Swal.fire({
      title: '確定要刪除嗎？',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: '取消刪除',
      customClass: { confirmButton: 'btn btn-sm btn-primary', cancelButton: 'btn btn-sm btn-secondary' },
      confirmButtonText: '確定刪除'
    }).then(function (result) {
      if (result.value) {
        Rails.ajax({ url: link, type: 'delete' })
      }
    })
  }
}
