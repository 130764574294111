import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    paramName: String
  }

  connect() {
    Dropzone.autoDiscover = false

    if (this.element.querySelector('.dropzoneArea')) {
      this.dz = new Dropzone('.dropzoneArea', {
        addRemoveLinks: true,
        url: this.element.action,
        paramName: this.paramNameValue,
        maxFiles: 1,
        autoProcessQueue: false
      }).on('sending', function (file, xhr, formData) {
        xhr.setRequestHeader('accept', 'application/javascript')
        let form = this.element

        ;[...(new FormData(form)).entries()].forEach(pair => {
          formData.append(pair[0], pair[1])
        })
      }.bind(this)).on('success', function (file, rep) {
        let script = document.createElement('script')
        script.innerHTML = rep
        document.body.appendChild(script)
      })
    }
  }

  disconnect() {
    if (this.dz) { this.dz.disable() }
  }

  upload(e) {
    e.preventDefault()
    e.stopPropagation()

    this.dz.processQueue()
  }
}
