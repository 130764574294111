import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    Dropzone.autoDiscover = false

    if (document.querySelector('.dropzoneArea')) {
      let method = 'post'

      this.dz = new Dropzone('.dropzoneArea', {
        addRemoveLinks: true,
        url: this.element.action,
        paramName: 'asset_file[template]',
        maxFiles: 1,
        autoProcessQueue: false
      })

      this.dz.on('sending', function (file, xhr, formData) {
        xhr.setRequestHeader('accept', 'application/javascript')
        let form = this.element

        ;[...(new FormData(form)).entries()].forEach(pair => {
          formData.append(pair[0], pair[1])
        })
      }.bind(this)).on('success', function (file, rep) {
        let script = document.createElement('script')
        script.innerHTML = rep
        document.body.appendChild(script)
      })
    }
  }

  upload(e) {
    e.preventDefault()
    e.stopPropagation()

    if (this.dz.getQueuedFiles().length > 0) {
      this.dz.processQueue()
    } else {
      Rails.ajax({
        type: 'POST',
        url: this.element.action,
        data: new FormData(this.element)
      })
    }
  }

  toggleDetail(e) {
    let id = e.target.parentElement.parentElement.parentElement.id
    let target = $(`#${id}-detail`)
    let caret = $(e.target)

    target.toggleClass('d-none')

    caret.addClass('fa-caret-down').removeClass('fa-caret-right')
    if (target.hasClass('d-none')) {
      caret.addClass('fa-caret-right').removeClass('fa-caret-down')
    }
  }
}
