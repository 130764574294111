export default class extends Controller {
  static values = {
    userNone: Boolean,
    userReadonly: Boolean,
  }

  connect() {
    if (this.userNoneValue) {
      Swal.fire({
        icon: 'question',
        text: '權限不足，請聯繫管理員',
        confirmButtonText: '確定',
        customClass: { confirmButton: 'btn btn-sm btn-primary', cancelButton: 'btn btn-sm btn-secondary' }
      }).then(result => Turbolinks.visit('/'))
    }
  }
}
