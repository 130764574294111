import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['day']

  connect() {
    this.dayTargets.forEach(target => {
      let days = target.textContent.match(/\d+/)[0]
      target.innerHTML = target.innerHTML.replace(days, `<span class="text-primary">${days}</span>`)
    })
  }
}
