import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['record', 'status']
  connect() {
    this.adjustExpiredAtString()
  }

  adjustExpiredAtString() {
    this.statusTargets.forEach((td, idx) => {
      let expiredAt = $(td).find('.expired_at')
      if (expiredAt.text().trim().length > 0) expiredAt.prepend('- ')
    })
  }
}
